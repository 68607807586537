<template>
  <vs-popup maxWidth="100vw" class="popup" :title="popup.title" :active.sync="popup.popupActive">
    <div v-if="popup.loadedImages && popup.loadedImages.length">
      <v-card class="mx-auto" max-width="100%" width="100%" height="100vh" max-height="80vh" outlined>
        <v-carousel v-model="popup.position" id="car-popup" height="100%">
          <v-carousel-item v-for="(img, i) in popup.loadedImages" :key="i">
            <v-img contain width="auto" height="auto" max-height="100%" max-width="100%" class="m-auto white--text align-end cursor-pointer" :src="img"> </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </div>
  </vs-popup>
</template>

<script>
export default {
  name: 'CarrouselPopup',
  components: {
    'vs-popup': () => import('@/components/Popup/VSPopup.vue')
  },
  props: {
    popup: {
      position: Number,
      title: String,
      popupActive: Boolean,
      loadedImages: Array
    }
  }
}
</script>

<style>
#car-popup .v-responsive__content {
  display: flex;
}
</style>
