
































import CarrouselPopup from '../components/CarrouselPopup.vue';
import rService from '../services/RequestService/RequestServiceSetup';
export default {
  components: {
    CarrouselPopup,
  },
  data() {
    return {
      timeout: null,
      botUsers: {},
      botChecks: {},
      popup: {
        title: '',
        popupActive: false,
        loadedImages: [],
      },
      popupActive: false,
      bots: [
        {
          carrouselM: 0,
          tag: 'discord',
          name: this.t('discord', 'name'),
          description: this.t('discord', 'description'),
          images: [require('@/assets/bots/discord.png'), require('@/assets/bots/discord_2.png'), require('@/assets/bots/discord_3.png'), require('@/assets/bots/discord_4.png')],
          link: 'https://checkleaked.cc/bot/index.html',
          joinText: this.t('discord', 'joinText'),
        },
        {
          carrouselM: 0,
          tag: 'telegram',
          name: this.t('telegram', 'name'),
          description: this.t('telegram', 'description'),
          images: [require('@/assets/bots/telegram_1.png'), require('@/assets/bots/telegram_2.png'), require('@/assets/bots/telegram_3.png')],
          link: 'https://t.me/leakcheckcc_bot',
          joinText: this.t('telegram', 'joinText'),
        },
        {
          carrouselM: 0,
          tag: 'slack',
          name: this.t('slack', 'name'),
          description: this.t('slack', 'description'),
          images: [require('@/assets/bots/slack.jpg'), require('@/assets/bots/slack_1.jpg')],
          link: 'https://checkleaked.cc/slack-bot/index.html',
          joinText: this.t('slack', 'joinText'),
        },
      ],
    };
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer);
  },
  beforeMount() {
    this.getTotalChecks();
  },
  methods: {
    async getTotalChecks() {
      await rService.get('bot_users', true).then((res) => {
        res.forEach(({ bot_type, users, checks }) => {
          this.$set(this.botUsers, bot_type, users);
          this.$set(this.botChecks, bot_type, checks);
        });
      });
      this.timer = setTimeout(() => {
        this.getTotalChecks();
      }, 30000);
    },
    t(bot, text) {
      return this.$vuetify.lang.t(`$vuetify.Bots_${bot}.${text}`);
    },
    openDialog(bot) {
      this.popup = {
        position: bot.carrouselM,
        title: bot.name,
        popupActive: true,
        loadedImages: bot.images,
      };
    },
    openPopup(bot) {
      window.open(bot.link);
    },
  },
};
